import ArrowDown from '@/app/icons/ArrowDown/ArrowDown'
import { BookSpark } from '@/app/icons/BookSpark'
import { DevLogo } from '@/app/icons/DevLogo'
import { RuleSettings } from '@/app/icons/RuleSettings'
import { ROUTES } from '@/app/lib/constants'
import { ICategory } from '@/types/category'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import {
  MdOutlineAnalytics,
  MdOutlineCampaign,
  MdOutlineCategory,
  MdOutlineCloud,
  MdOutlineDraw,
  MdOutlineEco,
  MdOutlineGavel,
  MdOutlineLabelImportant,
  MdOutlineLeaderboard,
  MdOutlineLoyalty,
  MdOutlinePaid,
  MdOutlineSupervisedUserCircle,
  MdOutlineTerminal,
  MdOutlineTranslate,
  MdOutlineVerifiedUser,
  MdOutlineWorkOutline,
} from 'react-icons/md'

type CoursesFilterRowProps = {
  categories: ICategory[]
  wrapperProps?: React.HTMLProps<HTMLDivElement>
} & React.HTMLProps<HTMLDivElement>

const HARDCODED_TOP_CATEGORIES = [
  'artificial-intelligence',
  'cybersecurity',
  'leadership',
  'languages',
]

const CATEGORY_ICONS = new Map<string, React.ReactNode>([
  ['design', <MdOutlineDraw key="design" />],
  ['product-management', <MdOutlineCategory key="product-management" />],
  ['languages', <MdOutlineTranslate key="languages" />],
  [
    'artificial-intelligence',
    <BookSpark className="h-4 w-4" key="artificial-intelligence" />,
  ],
  ['finance', <MdOutlinePaid key="finance" />],
  ['marketing', <MdOutlineCampaign key="marketing" />],
  ['data', <MdOutlineAnalytics key="data" />],
  ['business', <MdOutlineWorkOutline key="business" />],
  ['cybersecurity', <MdOutlineVerifiedUser key="cybersecurity" />],
  ['leadership', <MdOutlineLeaderboard key="leadership" />],
  ['cloud-engineering', <MdOutlineCloud key="cloud-engineering" />],
  ['devops', <DevLogo className="h-4 w-4" key="devops" />],
  ['green-skills', <MdOutlineEco key="green-skills" />],
  ['law', <MdOutlineGavel key="law" />],
  ['operations', <RuleSettings className="h-4 w-4" key="operations" />],
  ['people-and-hr', <MdOutlineSupervisedUserCircle key="people-and-hr" />],
  ['sales', <MdOutlineLoyalty key="sales" />],
  ['software-engineering', <MdOutlineTerminal key="software-engineering" />],
])

export const CoursesFilterRow = ({
  categories = [],
  wrapperProps,
  ...props
}: CoursesFilterRowProps) => {
  const t = useTranslations('CoursesFilterRow')

  return (
    <div {...wrapperProps} className={`${wrapperProps?.className ?? ''}`}>
      <div
        {...props}
        className={`flex items-center justify-between gap-5 py-5 sm:py-7 ${props.className ?? ''}`}
      >
        <div className="block overflow-hidden">
          <h2 className="font-title text-2xl md:text-3xl">
            {t('most_popular_categories')}
          </h2>
          <ScrollableList className="relative mt-8 flex">
            {categories
              .sort((a, b) => {
                return (b.totalCourses ?? 0) - (a.totalCourses ?? 0)
              })
              .sort((a, b) => {
                const aIndex = [...HARDCODED_TOP_CATEGORIES]
                  .reverse()
                  .indexOf(a.slug)
                const bIndex = [...HARDCODED_TOP_CATEGORIES]
                  .reverse()
                  .indexOf(b.slug)
                return bIndex - aIndex
              })
              .map(({ _id, slug, totalCourses, name }) => {
                const Icon = () =>
                  CATEGORY_ICONS.get(slug) ?? (
                    <MdOutlineLabelImportant key={slug} />
                  )

                return (
                  <Link
                    key={slug}
                    href={`${ROUTES.search}?categories=${_id}`}
                    className="shadow-category-button flex items-center gap-2 rounded-md p-3 font-title hover:text-mydra-purple"
                  >
                    <div className="flex w-max shrink-0 justify-center overflow-hidden rounded-lg bg-mydra-gray p-3">
                      <Icon />
                    </div>
                    <div className="flex items-center gap-2 whitespace-nowrap">
                      {`${name} (${totalCourses})`}
                      <ArrowDown className="h-4 w-auto -rotate-90" />
                    </div>
                  </Link>
                )
              })}
          </ScrollableList>
        </div>
      </div>
    </div>
  )
}

export function ScrollableList({
  children,
  ...props
}: PropsWithChildren<React.HTMLProps<HTMLDivElement>>) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [showLeft, setShowLeft] = useState(false)
  const [showRight, setShowRight] = useState(false)

  const PADDING = 8

  useEffect(() => {
    const observer = new ResizeObserver(() => checkScrollButtons())

    if (containerRef.current) {
      observer.observe(containerRef.current)
      checkScrollButtons()
    }

    return () => observer.disconnect()
  }, [])

  const checkScrollButtons = () => {
    const container = containerRef.current
    if (!container) return

    const { scrollLeft, scrollWidth, clientWidth } = container

    setShowLeft(scrollLeft > PADDING)
    setShowRight(scrollLeft + clientWidth < scrollWidth - PADDING)
  }

  const scroll = (direction: 'left' | 'right') => {
    const container = containerRef.current
    if (!container) return

    const items = Array.from(container.children) as HTMLElement[]
    const containerLeft = container.scrollLeft
    const containerRight = containerLeft + container.clientWidth

    let targetScroll = containerLeft

    if (direction === 'right') {
      const nextItem = items.find(
        (item) => item.offsetLeft + item.offsetWidth > containerRight
      )
      if (nextItem) targetScroll = nextItem.offsetLeft - PADDING
    } else {
      const prevItems = items.filter(
        (item) => item.offsetLeft + item.offsetWidth < containerLeft
      )
      if (prevItems.length > 0) {
        const prevItem = prevItems[prevItems.length - 1]
        targetScroll = prevItem.offsetLeft - PADDING
      }
    }

    container.scrollTo({ left: targetScroll, behavior: 'smooth' })
  }

  return (
    <div {...props} className={`relative w-full ${props.className ?? ''}`}>
      {showLeft && (
        <div className="absolute left-0 h-full bg-gradient-to-l from-transparent to-white to-[25%] pr-4">
          <button
            className="relative top-1/2 z-10 -translate-y-1/2 rounded-full bg-mydra-mouse p-3"
            onClick={() => scroll('left')}
          >
            <ArrowDown className="h-4 w-auto rotate-90" />
          </button>
        </div>
      )}

      <div
        ref={containerRef}
        className="no-scrollbar flex gap-2 overflow-scroll scroll-smooth"
        style={{ padding: PADDING }}
        onScroll={checkScrollButtons}
      >
        {children}
      </div>

      {showRight && (
        <div className="absolute right-0 h-full bg-gradient-to-r from-transparent to-white to-[25%] pl-4">
          <button
            className="relative top-1/2 z-10 -translate-y-1/2 rounded-full bg-mydra-mouse p-3"
            onClick={() => scroll('right')}
          >
            <ArrowDown className="h-4 w-auto -rotate-90" />
          </button>
        </div>
      )}
    </div>
  )
}
