import { ICourse } from '@/types/course'
import { Badge, BadgeColors, BadgeTypes } from '../../shared/components'

import { ROUTES } from '@/app/lib/constants'
import { IEducationProvider } from '@/types/education-provider'
import { useCourseCategoryImage } from '@/app/hooks/useCourseCategoryImage'
import { getCardImage } from '../helpers'
import { useTranslations } from 'next-intl'
import { useCohortHelpers } from '@/app/hooks/useCohortHelpers'
import Link from 'next/link'
import { ImageWithFallback } from '../../shared/components/image-with-fallback'
import { CoursePrice } from '@/app/components/CoursePrice'

interface CourseRelatedProps {
  course: ICourse
}

export const CourseCard = ({ course }: CourseRelatedProps) => {
  const { isCohortActive } = useCohortHelpers()
  const t = useTranslations('Courses')

  const { _id, name, cohorts = [], educationProvider } = course
  const { categoryImage } = useCourseCategoryImage(course)

  const cohortToDisplay = cohorts?.filter(isCohortActive)?.[0] ?? cohorts?.[0]

  const { logoUrl: epLogo, name: epName } =
    (educationProvider as IEducationProvider) ?? {}

  const locations = [cohortToDisplay?.location].filter(Boolean)
  const formats = [cohortToDisplay?.format].filter(Boolean)

  const courseHref = ROUTES.course.replace(':id', _id ?? '')

  return (
    <Link
      href={courseHref}
      className="flex flex-col gap-4 px-4 py-6 bg-white border cursor-pointer border-lines rounded-2xl hover:drop-shadow-card-active-on-slider"
    >
      <div className="flex flex-row items-center gap-3">
        {epLogo && (
          <ImageWithFallback
            src={epLogo}
            alt={t('school_avatar')}
            width={40}
            height={40}
            className="w-10 h-10 border rounded-full border-lines"
          />
        )}
        <div className="text-sm font-medium text-mydra-black">{epName}</div>
      </div>
      <div className="w-full aspect-[2.16] relative">
        <ImageWithFallback
          className="object-cover"
          src={course.imageUrl ?? categoryImage?.src ?? getCardImage(course)}
          alt={t('course_image')}
          fill
          fallbackSrc={categoryImage?.src ?? getCardImage(course)}
        />
      </div>
      <div className="text-base font-semibold sm:text-lg line-clamp-2 text-ellipsis">
        {name}
      </div>
      <div className="flex flex-row gap-1 overflow-x-scroll no-scrollbar">
        {locations.slice(0, 2).map((location, i) => (
          <Badge
            key={`locations--${location}--${i}`}
            color={BadgeColors.YELLOW}
            type={BadgeTypes.ROUND_SMALL}
          >
            {location.charAt(0).toUpperCase() + location.slice(1)}
          </Badge>
        ))}

        {formats.slice(0, 2).map((format, i) => (
          <Badge
            key={`formats--${format}--${i}`}
            color={BadgeColors.GRAY}
            type={BadgeTypes.ROUND_SMALL}
          >
            {format.charAt(0).toUpperCase() + format.slice(1)}
          </Badge>
        ))}
      </div>

      <div className="mt-auto text-base font-semibold sm:text-lg">
        <CoursePrice course={course} />
      </div>
    </Link>
  )
}
