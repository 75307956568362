import qs from 'query-string'
import { v4 as uuid } from 'uuid'
import { ApiService, RequestOptions } from './ApiService'

import {
  IApiIndividualResponse,
  IPaginationParams,
  ISortingParams,
  PaginatedQueryResponse,
} from '@/types/api'
import { CourseInclude, ICourse, IDuration } from '@/types/course'
import { NewPaymentOption } from '@/types/payment'
import { IBudget } from '@/types/session'
import { OptionProps } from '../modules/shared/components'

export type ICourseFilters = Partial<{
  categories: OptionProps[]
  subCategories: OptionProps[]
  skills: OptionProps[]
  educationProviders: OptionProps[]
  types: OptionProps[]
  durations: OptionProps[]
  budget: IBudget
  duration: IDuration | null
  paymentOptions: NewPaymentOption[]
}>

export type ICoursesGetParams = {
  filters?: ICourseFilters
  pagination?: IPaginationParams
}

export class CourseService extends ApiService {
  static GET_PATH = '/courses'
  static DEFAULT_PAGE_SIZE = 8

  // TODO: remove this method
  static async getAllCourses(
    filter = {},
    queryStringParams = [],
    requestOptions?: RequestOptions
  ) {
    const params = Array.isArray(filter)
      ? filter.join('&')
      : new URLSearchParams(filter).toString()

    const additionalParams = queryStringParams?.join('&') ?? ''

    const { data, ...rest } = await ApiService.get(
      `${CourseService.GET_PATH}?${params}${
        additionalParams ? `&${additionalParams}` : ''
      }${
        !params.includes('limit')
          ? `&limit=${CourseService.DEFAULT_PAGE_SIZE}`
          : ''
      }&published=true`,
      requestOptions
    )
    return {
      data: data.map((course) => {
        return {
          _key: uuid(), // FIXME: remove this when the backend can provide unique courses
          ...course,
        }
      }),
      ...rest,
    }
  }

  static async getCourses(
    filters: ICourseFilters = {},
    pagination: IPaginationParams = {},
    sorting: ISortingParams = {},
    requestOptions?: RequestOptions
  ): Promise<PaginatedQueryResponse<ICourse>> {
    const queryString = qs.stringify({
      published: true,
      ...filters,
      ...pagination,
      ...(sorting && {
        sort: Object.entries(sorting).reduce((acc, [fieldName, sort]) => {
          return [...acc, `${fieldName}[${sort}]`]
        }, []),
      }),
    })

    const { data, ...rest } = await ApiService.get(
      `${CourseService.GET_PATH}?${queryString}`,
      requestOptions
    )

    return {
      data: data.map((course) => {
        return {
          _key: uuid(), // FIXME: remove this when the backend can provide unique courses
          ...course,
        }
      }),
      ...rest,
    }
  }

  static async getCourseById(
    courseId: string,
    requestOptions?: RequestOptions,
    includes?: CourseInclude[]
  ): Promise<IApiIndividualResponse<ICourse>> {
    const queryString = qs.stringify({
      include: includes?.join(','),
    })

    return ApiService.get(
      `${CourseService.GET_PATH}/${courseId}?${queryString}`,
      requestOptions
    )
  }
}
