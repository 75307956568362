import Modal from '@/app/components/Modal/Modal'
import { MultiRangeSlider } from '@/app/components/MultiRangeSlider'
import { useCategories } from '@/app/hooks/data-hooks/useCategories'
import { useEducationProviders } from '@/app/hooks/data-hooks/useEducationProviders'
import { useSkills } from '@/app/hooks/data-hooks/useSkills'
import { useFormatters } from '@/app/hooks/useFormatters'
import { MAX_COURSE_FILTER_PRICE } from '@/app/lib/constants'
import { translateConstant } from '@/app/lib/i18n'
import { AutoComplete, Button } from '@/app/modules/shared/components'
import { NewPaymentOption } from '@/types/payment'
import { useTranslations } from 'next-intl'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MultiAutoComplete } from '../../shared/components/multi-auto-complete/MultiAutoComplete'
import { COURSE_DURATIONS, COURSE_TYPES } from '../constants'

interface CourseFiltersModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (data: any) => Promise<void>
  onClear?: () => void
  hideOnClear?: boolean
  defaultValues?: any
}

export const CourseFiltersModal = ({
  open,
  onSubmit,
  onClear,
  onClose,
  hideOnClear,
  defaultValues,
}: CourseFiltersModalProps) => {
  const t = useTranslations('Courses')
  const tFilter = useTranslations('FilterModal')
  const { formatCurrencyValue } = useFormatters()

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      categories: [],
      subCategories: [],
      skills: [],
      educationProvider: [],
      paymentOptions: [],
      type: [],
      maxDuration: null,
      agreementType: defaultValues?.agreementType ?? '',
      budget: {
        min: defaultValues?.budget?.min ?? 0,
        max: defaultValues?.budget?.max ?? MAX_COURSE_FILTER_PRICE,
      },
      ...defaultValues,
    },
  })

  const numberOfSelectedFilters = Object.entries(watch()).filter(
    ([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0
      }
      if (key === 'budget') {
        return value.min !== 0 || value.max !== MAX_COURSE_FILTER_PRICE
      }
      return !!value
    }
  ).length

  const {
    data: { data: categories = [] } = {},
    isLoading: isLoadingCategories,
  } = useCategories({}, { limit: 500 }, { retry: false })

  const selectedCategoriesValues = watch('categories')
  const selectedCategories = (categories ?? []).filter(({ _id }) => {
    return Array.isArray(selectedCategoriesValues)
      ? selectedCategoriesValues?.find(({ id }) => id === _id)
      : [selectedCategoriesValues]?.filter(Boolean)
  })

  const subCategories = categories
    .filter((cat) =>
      (selectedCategories.map(({ _id }) => _id) ?? []).includes(cat._id)
    )
    .map((cat) => cat.subCategories)
    .flat()

  const { data: { data: skills = [] } = {}, isLoading: isLoadingSkills } =
    useSkills({}, { limit: 500 }, { retry: false })
  const {
    data: { data: educationProviders = [] } = {},
    isLoading: isLoadingEducationProvider,
  } = useEducationProviders({}, { limit: 500 }, { retry: false })

  const isLoading =
    isLoadingSkills || isLoadingCategories || isLoadingEducationProvider

  const getDefaultValues = useCallback(
    () =>
      Object.entries(defaultValues ?? {}).reduce((acc, [key, value]) => {
        if (
          [
            'categories',
            'subCategories',
            'skills',
            'type',
            'educationProvider',
            'paymentOptions',
          ].includes(key)
        ) {
          return {
            ...acc,
            [key]: (Array.isArray(value)
              ? value.map((categoryId) => ({ id: categoryId }))
              : value
                ? [{ id: value }]
                : []
            ).filter(Boolean),
          }
        }

        if (['maxDuration'].includes(key)) {
          return {
            ...acc,
            [key]: value ? { id: value } : null,
          }
        }

        return {
          ...acc,
          [key]: value ?? null,
        }
      }, {}),
    [defaultValues]
  )

  useEffect(() => {
    if (isLoading) {
      return
    }

    reset()
    const newValues = getDefaultValues()
    for (const key in newValues) {
      setValue(key, newValues[key])
    }
  }, [defaultValues, setValue, getDefaultValues, reset, isLoading])

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title={t('all_filters', { numberOfSelectedFilters })}
      className="relative md:min-w-[780px] max-h-[95vh]"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-5 w-full"
      >
        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <Controller
            name="categories"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('categories')}
                subTitle={t('add_one_or_more')}
                options={[
                  ...categories.map(({ _id: id, name }) => ({
                    id,
                    name,
                  })),
                ].sort((a, b) => a.name.localeCompare(b.name))}
                {...field}
              />
            )}
          />

          <Controller
            name="subCategories"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('subcategories')}
                subTitle={t('add_one_or_more')}
                options={[
                  ...subCategories.map(({ slug: id, name }) => ({
                    id,
                    name,
                  })),
                ].sort((a, b) => a.name.localeCompare(b.name))}
                {...field}
              />
            )}
          />
        </div>
        <div className="mx-8 h-[1] border-b border-lines"></div>
        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <Controller
            name="skills"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('skills')}
                subTitle={t('add_one_or_more')}
                options={[
                  ...skills.map(({ name: id, name }) => ({
                    id,
                    name,
                  })),
                ].sort((a, b) => a.name.localeCompare(b.name))}
                {...field}
              />
            )}
          />

          <Controller
            name="educationProvider"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('education_providers')}
                subTitle={t('add_one_or_more')}
                options={[
                  ...educationProviders.map(({ _id: id, name }) => ({
                    id,
                    name,
                  })),
                ].sort((a, b) => a.name.localeCompare(b.name))}
                {...field}
              />
            )}
          />
        </div>
        <div className="mx-8 h-[1] border-b border-lines"></div>
        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <Controller
            name="type"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('course_type')}
                subTitle={t('add_one_or_more')}
                options={COURSE_TYPES.map((courseType) =>
                  translateConstant(courseType, tFilter, 'type')
                )}
                {...field}
              />
            )}
          />

          <Controller
            name="maxDuration"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <AutoComplete
                title={t('course_duration')}
                subTitle={t('add_one')}
                options={COURSE_DURATIONS.map((duration) =>
                  translateConstant(duration, tFilter, 'duration')
                )}
                {...field}
              />
            )}
          />
        </div>

        <div className="mx-8 h-[1] border-b border-lines"></div>

        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <div className="col-span-2">
            <Controller
              name="paymentOptions"
              control={control}
              disabled={isLoading}
              render={({ field }) => (
                <MultiAutoComplete
                  title={'Payment Options'}
                  subTitle=""
                  options={[
                    {
                      id: 'any',
                      name: tFilter('payment_options.any'),
                    },
                    {
                      id: NewPaymentOption.FixPay,
                      name: tFilter('payment_options.fix_pay'),
                    },
                    {
                      id: NewPaymentOption.Fundae,
                      name: tFilter('payment_options.fundae'),
                    },
                    {
                      id: NewPaymentOption.Isa,
                      name: tFilter('payment_options.isa'),
                    },
                  ]}
                  {...field}
                  onChange={(value) => {
                    if (value.some((option) => option.id === 'any')) {
                      field.onChange([])
                    } else {
                      field.onChange(value)
                    }
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="mx-8 h-[1] border-b border-lines"></div>

        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <div className="col-span-2">
            <label htmlFor="budget" className="text-sm">
              {tFilter('price-range')}
            </label>
            <div className="text-xs text-gray-light">
              {tFilter('price-example')}
            </div>
            <Controller
              control={control}
              name="budget"
              render={({ field: { onChange, value, ...field } }) => (
                <MultiRangeSlider
                  big
                  {...field}
                  onChange={onChange}
                  value={value}
                  min={0}
                  id="budget"
                  max={MAX_COURSE_FILTER_PRICE} // TODO: get from API
                  className="my-9 md:mx-24"
                  valFormatter={formatCurrencyValue}
                />
              )}
            />
          </div>
        </div>
        <footer className="flex sticky bottom-0  bg-white z-10 flex-row justify-between items-center px-8 py-4 font-medium border-t border-lines">
          {!hideOnClear && (
            <Button
              color="white"
              onClick={(e) => {
                e.preventDefault()
                onClear?.()
              }}
              isLoading={isLoading}
              disabled={isSubmitting}
            >
              {t('clear_filters')}
            </Button>
          )}
          <Button
            type="submit"
            color="black"
            className="ml-auto"
            isLoading={isLoading || isSubmitting}
          >
            {t('show_results')}
          </Button>
        </footer>
      </form>
    </Modal>
  )
}
