import * as React from 'react'

const ArrowDown = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="m4 12 1.41-1.41L11 16.17V4h2v12.17l5.58-5.59L20 12l-8 8-8-8Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M24 0H0v24h24z" />
      </clipPath>
    </defs>
  </svg>
)
export default ArrowDown
